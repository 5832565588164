type NoResultProps = {
  message?: string;
};

export const NoResult = ({ message }: NoResultProps) => {
  return (
    <div className="grid justify-center">
      <div className="flex flex-col items-center">
        <img
          src="/no-result-search.png"
          alt={message}
          className="-mt-10 md:-mt-16 w:48 h-48 md:w-96 md:h-96"
        />
        <div className="text-xs md:text-base font-semibold -mt-4 md:-mt-12">
          {message}
        </div>
      </div>
    </div>
  );
};
